import Header from '@/components/layout/Header'
import Footer from '@/components/layout/Footer'
import { DiscountBadge } from '@/components/cta/DiscountBadge'
import { CookieConsent } from '@/components/popups/CookieConsent'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store/reducers'
import { setCouponIfAvailable } from '@/store/actions/checkout'
import { SimplePreloader } from '@/components/preloader/SimplePreloader'
import { useSplitTest } from '@/hooks/SplitTest'
import { emitter } from '@/utils/emitter'
import { useQuery } from '@/utils/url'
import Link from 'next/link'
import { NavbarWithSubmenu } from '@/components/common/NavbarWithSubmenu'
import moment from 'moment'

type Props = {
  className?: string
}

export const PrimaryLayout: React.FC<Props> = ({ className = '', children }) => {
  const checkoutState = useSelector((state: RootState) => state.checkout)
  const [discountBanner, showDiscountBanner] = useState(false)
  const [bannerAvailable, showBannerAvailable] = useState(false)
  const [expireDiscount, showExpireDiscount] = useState(false)
  const [expiresIn, setExpiresIn] = useState('')
  const [founderPairingBanner, showFounderPairingBanner] = useState(false)
  const [blackFridayBanner, showBlackFridayBanner] = useState(false)
  const [discountSecBanner, showDiscountSecBanner] = useState('')
  const [couponTitle, showCouponTitle] = useState(false)
  const dispatch = useDispatch()
  const checkoutLink = useQuery('/checkout')
  const postLink = useQuery('/headstart-planning-course/')

  useEffect(() => {
    const getCouponAction = async () => {
      const action = await setCouponIfAvailable()
      dispatch(action)
    }

    getCouponAction()
  }, [])

  useEffect(() => {
    if (checkoutState.coupon.available && checkoutState.coupon.value === 'BlPgJoFuRD') {
      showDiscountBanner(true)
      showBannerAvailable(true)
    }
  }, [checkoutState.coupon])

  useEffect(() => {
    if (checkoutState.coupon.available && checkoutState.coupon.expires) {
      showExpireDiscount(true)
      showBannerAvailable(true)

      const today = moment()
      const expireData = moment(checkoutState.coupon.expires)
      const diff = expireData.diff(today)
      const formatted = moment(diff).format('D[d]:h[h]:mm[m]')
      setExpiresIn(formatted)
    }
  }, [checkoutState.coupon])

  // useEffect(() => {
  //   if (checkoutState.coupon.available && checkoutState.coupon.value === 'blackfriday100') {
  //     showBlackFridayBanner(true)
  //   }
  // }, [checkoutState.coupon])

  useEffect(() => {
    if (checkoutState.coupon.available && checkoutState.coupon.value === 'founder-coach-pairing') {
      showFounderPairingBanner(true)
      showBannerAvailable(true)
    }
  }, [checkoutState.coupon])

  useEffect(() => {
    if (checkoutState.coupon.available && checkoutState.coupon.value === 'EmA2for1') {
      showDiscountSecBanner('Elizabeth Ma')
      showBannerAvailable(true)
    }
  }, [checkoutState.coupon])

  useEffect(() => {
    if (checkoutState.coupon.available && checkoutState.coupon.value === 'Brittney2for1') {
      showDiscountSecBanner('Brittney')
      showBannerAvailable(true)
    }
  }, [checkoutState.coupon])

  useEffect(() => {
    if (checkoutState.coupon.available && checkoutState.coupon.annualDiscount.toFixed(2) > 0) {
      showCouponTitle(true)
      showBannerAvailable(true)
    }
  }, [checkoutState.coupon])

  return (
    <>
      {/* <SimplePreloader lifetime={1500}></SimplePreloader> */}
      {!expireDiscount && (
        <div>
          {discountBanner && <div className={'banner flex items-center justify-center w-full'} style={{position: 'fixed', zIndex: 9999}}>
            <Link href={checkoutLink} passHref>
              <a className='flex w-full h-full justify-center'>
                <span className="mr-[10px] lg:mr-[30px] flex items-center text-left font-extralight leading-4">
                  <b className='text-[20px] mr-[10px] ml-[10px]'>🎉</b><span><b className='font-bold'>Summer Flash Special:</b> Buy your <em>1st month</em>, get your 2nd month <b className='font-bold'>free</b>.</span><b className='text-[20px] mr-[10px] ml-[10px] hidden md:block'>🎉</b>
                </span>
              </a>
            </Link>
          </div>}
          {founderPairingBanner && <div className={'banner flex items-center justify-center w-full'}>
            <Link href={checkoutLink} passHref>
              <a className='flex w-full h-full justify-center'>
                <span className="mr-[10px] lg:mr-[30px] flex items-center text-left font-extralight leading-4">
                  <b className='text-[20px] mr-[10px] ml-[10px]'>🎉</b><span>Founder Coach Matching Link Activated - Proceed To Checkout & Sign Up!</span><b className='text-[20px] mr-[10px] ml-[10px] hidden md:block'>🎉</b>
                </span>
              </a>
            </Link>
          </div>}
          {blackFridayBanner && <div className={'banner flex items-center justify-center w-full'} style={{position: 'absolute', zIndex: 9999}}>
            <Link href={checkoutLink} passHref>
              <a className='flex w-full h-full justify-center'>
                <span className="mr-[10px] lg:mr-[30px] flex items-center text-left font-extralight leading-4">
                  <span><b className='text-[10px] md:text-[14px] mr-[10px] ml-[10px]'>🔥 <b className='font-bold'>Black Friday Special:</b> Get <span className='underline'>$100 Off</span> Your First Month of Coaching <span className='hidden lg:inline'>(Applied at Checkout)</span> 🔥</b></span>
                </span>
              </a>
            </Link>
          </div>}
          {discountSecBanner && <div className={'banner flex items-center justify-center w-full'} style={{position: 'fixed', zIndex: 9999}}>
            <Link href={checkoutLink} passHref>
              <a className='flex w-full h-full justify-center'>
                <span className="mr-[10px] lg:mr-[30px] flex items-center text-left font-extralight leading-4">
                  <b className='text-[20px] mr-[10px] ml-[10px]'>🎉</b><span><b className='font-bold'>{discountSecBanner} Special:</b> Buy your <em>1st month</em>, get your 2nd month <b className='font-bold'>free</b>.</span><b className='text-[20px] mr-[10px] ml-[10px] hidden md:block'>🎉</b>
                </span>
              </a>
            </Link>
          </div>}
          {couponTitle && !discountBanner && !blackFridayBanner && !discountSecBanner && <div className={'banner flex items-center justify-center w-full'} style={{position: 'absolute', zIndex: 9999}}>
            <Link href={checkoutLink} passHref>
              <a className='flex w-full h-full justify-center'>
                <span className="mr-[10px] lg:mr-[30px] flex items-center text-left font-extralight leading-4">
                  <b className='text-[12px] md:text-[14px] mr-[10px] ml-[10px]'>🎉 Your discount of up to <b>${checkoutState.coupon.annualDiscount.toFixed(2)}</b> will be applied <span className='underline'>at payment!</span></b>
                </span>
              </a>
            </Link>
          </div>}
          {/* <div className={'banner flex items-center justify-center w-full'}>
            <Link href={postLink} passHref>
              <a className='flex w-full h-full justify-center'>
                <span className="mr-[10px] lg:mr-[30px] flex items-center text-left font-extralight leading-4">
                  <span><b className='text-[10px] md:text-[14px] mr-[10px] ml-[10px]'>🔥 <b className='font-bold'>Join by Jan 31st</b> and get our yearly goal planning course <b className='font-bold'>free!</b> <span className='underline'>Learn More</span> 🔥</b></span>
                </span>
              </a>
            </Link>
          </div> */}
        </div>
      )}
      {
        expireDiscount &&
        <div className={'banner flex items-center justify-center w-full'}>
          <Link href={checkoutLink} passHref>
            <a className='flex w-full h-full justify-center'>
              <span className="mr-[10px] lg:mr-[30px] flex items-center text-left font-extralight leading-4">
                <span className='text-center'><b className='text-[10px] md:text-[14px] mr-[10px] ml-[10px]'>🔥 Your <b>${checkoutState.coupon.discount.toFixed(2)}</b> off lifetime discount has been activated! <span className='underline'>Go to checkout to redeem</span>. <b>Expires in: {expiresIn}</b>. 🔥</b></span>
              </span>
            </a>
          </Link>
        </div>
      }
      <div className={`${className} relative overflow-hidden`}>
        <header className={'relative'}>
          <NavbarWithSubmenu pinOffset={bannerAvailable ? 40 : 0}/>
        </header>
        <main>
          { children }
        </main>
        <Footer/>
        <CookieConsent/>
        <DiscountBadge/>
      </div>
    </>
  )
}
